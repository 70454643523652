<template>
  <v-dialog v-model="value" persistent width="450px">
    <v-card outlined>

      <v-card-title>
        <h4 class="primary--text">
        {{ $t('webhookSecretKeyDialog.title') }}
        </h4>
      </v-card-title>

      <v-card-text>

        <span>{{ $t('webhookSecretKeyDialog.description') }}</span>
        <v-textarea
          class="mt-5"
          :value="secretKey"
          outlined
          dense
          readonly
        ></v-textarea>

        <div class="d-flex">

            <v-btn
              color="primary"
              @click="close"
              depressed
              outlined
            >
              {{ $t('common.close') }}
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn
              color="primary"
              @click="regenerateWebhookSecretKey"
              :loading="loading"
            >
              {{ $t('webhookSecretKeyDialog.regenerateSecretKey') }}
            </v-btn>

        </div>
    </v-card-text>

    </v-card>
  </v-dialog>
</template>

<script>

import Service from '@/services'

export default {
  name: 'WebhookSecretKeyDialog',
  data () {
    return {
      loading: false
    }
  },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    secretKey: {
      type: String,
      default: ''
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    async regenerateWebhookSecretKey () {
      this.loading = true

      try {
        const response = await Service.put('Company/RegenerateWebhookSecretKey', {})

        this.$emit('secretKeyRegenerated', response.data)

        await this.$store.dispatch('setSuccessNotification',
          this.$t('common.confirmOperation'))
      } catch (e) {
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
