<template>
    <v-dialog v-model="isOpen" persistent width="450px">
      <v-card outlined>
        <v-card-title>
          <h4 class="primary--text">
            {{ $t('apiToken.dialog.label') }}
          </h4>
        </v-card-title>
        <v-card-text>
            <span class="mt-5">  {{ $t('apiToken.dialog.state') }}
            <b>{{ isEnabled
                ?`${$t('apiToken.dialog.active')}`
                : $t('apiToken.dialog.inactive')}}
            </b>
            </span>
            <v-spacer></v-spacer>
            <v-btn v-if="token" :color="!isEnabled ? 'green':'#ff7a70'" @click="addOrUpdateToken(false, !isEnabled)" outlined   >
                {{!isEnabled ? $t('apiToken.dialog.enableBtn') :  $t('apiToken.dialog.disableBtn') }}
            </v-btn>
            <br>
            Token:
            <v-textarea outlined :loading="isLoading" readonly v-model="bearerToken" height="200"></v-textarea>
          <v-card-actions>

            <v-btn v-if="!token" color="primary" @click="addOrUpdateToken()" depressed>
                {{ $t('apiToken.dialog.generateBtn') }}
            </v-btn>
            <v-btn v-else color="primary" @click="addOrUpdateToken(true)" depressed>
                {{ $t('apiToken.dialog.generateNewBtn') }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn  @click="close" outlined>
              {{ $t('common.close') }}
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
  </template>

<script>
import Service from '@/services'
export default {
  props: {
    isOpen: {
      type: Boolean
    },
    user: {
      type: Object
    }
  },
  data () {
    return {
      token: null,
      isEnabled: false,
      isLoading: false
    }
  },
  computed: {
    bearerToken () {
      return this.token ? 'Bearer ' + this.token : ''
    }
  },
  methods: {
    async getToken () {
      const id = this.user.id
      this.isLoading = true
      const { data } = await Service.get(`/User/GetIntegrationApiToken?userId=${id}`)
      this.token = data?.token === ''
        ? '***'
        : data?.token

      this.isEnabled = data?.isEnabled

      this.isLoading = false
    },
    async addOrUpdateToken (isReset, isEnabledNew) {
      const oldToken = this.token
      try {
        const id = this.user.id
        this.isLoading = true
        this.token = 'loading'
        await Service.post('/User/AddOrUpdateIntegrationApiToken', { UserId: id, IsReset: isReset, IsEnabled: isEnabledNew ?? this.isEnabled })

        await this.$store.dispatch(
          'setSuccessNotification',
          !this.token
            ? this.$t('apiToken.dialog.tokenAddedNotification')
            : isReset
              ? this.$t('apiToken.dialog.newTokenAddedNotification')
              : isEnabledNew
                ? this.$t('apiToken.dialog.tokenEnabledNotification')
                : this.$t('apiToken.dialog.tokenDisabledNotification')
        )
        this.getToken()
      } catch (e) {
        this.isLoading = false
        this.token = oldToken
      }
    },
    close () {
      this.$emit('close')
    }
  },
  watch: {
    isOpen: function (val) {
      if (val) { this.getToken() }
    }
  }
}
</script>
