<template>
  <v-card v-if="isMyCompanyRoute && !isWorker" flat>
    <v-card-text>
    <v-tabs v-model="tab" :height="60" show-arrows hide-slider center-active>
      <v-tab :ripple="false"> {{ $t('companiesDetails.policy') }} ({{ documents.policiesCount }}) </v-tab>
      <v-tab :ripple="false"> {{ $t('companiesDetails.licence') }}  ({{ documents.licenciesCount }}) </v-tab>
      <v-tab :ripple="false"> {{ $t('companiesDetails.certificate') }}  ({{ documents.certificatesCount }}) </v-tab>
      <v-tab :ripple="false"> {{ $t('companiesDetails.other') }}  ({{ documents.othersCount }}) </v-tab>
      <v-tab :ripple="false"> {{ $t('companiesDetails.archive') }}  ({{ documents.archivedCount }}) </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <DocumentsList
          v-if="tab == 0"
          :documentType="types.policy"
          :isMyCompanyRoute="isMyCompanyRoute"
          @update="getDocumentsCount"
        />
      </v-tab-item>
      <v-tab-item>
        <DocumentsList
          v-if="tab == 1"
          :documentType="types.licence"
          :isMyCompanyRoute="isMyCompanyRoute"
          @update="getDocumentsCount"
         />
      </v-tab-item>
      <v-tab-item>
        <DocumentsList
          v-if="tab == 2"
          :documentType="types.certificate"
          :isMyCompanyRoute="isMyCompanyRoute"
          @update="getDocumentsCount"
         />
      </v-tab-item>
      <v-tab-item>
        <DocumentsList
          v-if="tab == 3"
          :documentType="types.other"
          :isMyCompanyRoute="isMyCompanyRoute"
          @update="getDocumentsCount"
        />
      </v-tab-item>
      <v-tab-item>
        <Archive
          v-if="tab == 4"
          :isMyCompanyRoute="isMyCompanyRoute"
        />
      </v-tab-item>
    </v-tabs-items>
    </v-card-text>
  </v-card>
  <DocumentsTable v-else :documentTypes="types" />
</template>
<script>
import Service from '@/services'
import { mapGetters } from 'vuex'
import Archive from './Tabs/Archive.vue'
import DocumentsList from './Tabs/DocumentsList.vue'
import DocumentsTable from './Tabs/DocumentsTable.vue'
import * as type from '@/types/document-types'

export default {
  name: 'Documents',
  props: {
    isMyCompanyRoute: {
      type: Boolean,
      required: true
    }
  },
  components: {
    DocumentsList,
    Archive,
    DocumentsTable
  },
  data () {
    return {
      tab: null,
      types: {
        policy: type.POLICY,
        licence: type.LICENCE,
        certificate: type.CERTIFICATE,
        other: type.OTHER
      },
      documents: {
        archivedCount: 0,
        certificatesCount: 0,
        licenciesCount: 0,
        othersCount: 0,
        policiesCount: 0
      }
    }
  },
  created () {
    if (this.isMyCompanyRoute) {
      this.getDocumentsCount()
    }
  },
  computed: {
    ...mapGetters({
      isWorker: 'isWorker'
    })
  },
  methods: {
    async getDocumentsCount () {
      this.loading = true
      try {
        const { data } = await Service.get('/Company/GetCompanyDocumentCount', {
          companyId: this.$store.getters.userCommonData.companyId
        })
        this.documents = data
      } catch (e) {
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
<style scoped>
.v-tab {
  text-transform: none;
  border-left: solid 1px var(--v-separator-base);
  margin: 10px 0;
}
.v-tab:last-child {
  border-right: solid 1px var(--v-separator-base);
  margin: 10px 0;
}
</style>
