<template>
  <v-card class="pa-0 pb-10" tile>
    <Loader v-if="loading"/>
    <template v-else>
      <v-card-title>
        <h4 class="primary--text">
        {{ company.companyName }}
        </h4>
      </v-card-title>
      <v-card-subtitle>
        <span>ID: {{ company.displayId }}</span>
      </v-card-subtitle>
      <div class="background px-6">
        <v-tabs v-model="activeTab" :height="60" background-color="background" show-arrows hide-slider center-active>
          <v-tab :ripple="false">{{ $t('companiesDetails.informationTab') }}</v-tab>
          <v-tab :ripple="false">{{ $t('companiesDetails.documentsTab') }}</v-tab>
          <v-tab :ripple="false" v-if="!isMyCompanyRoute && !myCompany">{{ $t('companiesDetails.ratingTab') }}</v-tab>
          <v-tab :ripple="false">{{ $t('companiesDetails.employeesTab') }}</v-tab>
          <v-tab :ripple="false" v-if="displayIntegrationApiTab">{{ $t('companiesDetails.integrationApi') }}</v-tab>
        </v-tabs>
      </div>
      <v-tabs-items v-model="activeTab" class="py-4">
        <v-tab-item>
          <Information :isMyCompanyRoute="isMyCompanyRoute" @isIntegrationApiEnabledUpdate="setIsIntegrationApiEnabled"/>
        </v-tab-item>
        <v-tab-item>
          <Documents :isMyCompanyRoute="isMyCompanyRoute" />
        </v-tab-item>
        <v-tab-item v-if="!isMyCompanyRoute && !myCompany">
          <RatingsList :isMyCompanyRoute="isMyCompanyRoute" />
        </v-tab-item>
        <v-tab-item>
          <Employees :isMyCompanyRoute="isMyCompanyRoute" :isIntegrationApiEnabled="isIntegrationApiEnabled" />
        </v-tab-item>
        <v-tab-item v-if="displayIntegrationApiTab">
          <IntegrationApi :isMyCompanyRoute="isMyCompanyRoute"/>
        </v-tab-item>
      </v-tabs-items>
    </template>
  </v-card>
</template>

<script>
import Service from '@/services'

import Information from './Tabs/Information.vue'
import Documents from './Tabs/Documents.vue'
import RatingsList from './Tabs/RatingsList.vue'
import Employees from './Tabs/Employees.vue'
import IntegrationApi from './Tabs/IntegrationApi.vue'
import Loader from '@/components/Loader'

export default {
  name: 'CompanyDetails',
  components: {
    Information,
    Documents,
    RatingsList,
    Employees,
    IntegrationApi,
    Loader
  },
  computed: {
    isMyCompanyRoute () {
      return this.$route.name === 'MyCompany'
    },
    myCompany () {
      return this.$store.getters.userCommonData.companyId === this.$route.params.id
    },
    displayIntegrationApiTab () {
      return this.isMyCompanyRoute &&
        (this.$store.getters.isOwner || this.$store.getters.isManager) &&
        this.isIntegrationApiEnabled
    }
  },
  data () {
    return {
      isIntegrationApiEnabled: false,
      loading: false,
      activeTab: 0,
      company: null
    }
  },
  async created () {
    await this.fetchData()
  },
  methods: {
    setIsIntegrationApiEnabled (value) {
      this.isIntegrationApiEnabled = value
    },
    async fetchData () {
      this.loading = true
      try {
        const companyId = this.isMyCompanyRoute ? this.$store.getters.userCommonData.companyId : this.$route.params.id
        const { data } = await Service.get('/Company/GetCompanyDetails', { companyId: companyId })
        this.company = data
      } catch (e) {
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
<style scoped>
.v-tab::before {
    display: block;
    content: attr(title);
    font-weight: bold;
    height: 0;
    overflow: hidden;
    visibility: hidden;
}

/* .v-tab--active, */
.v-tab:hover {
  /* font-weight: bold; */
  color: var(--v-primary-base) !important;
  text-shadow: 0 0 .7px var(--v-primary-base), 0 0 .7px var(--v-primary-base);
}

.v-tab--active:hover,
.v-tab--active {
  font-weight: bold;
  color: var(--v-primary-base) !important;
  text-shadow: none;
}
.v-tab {
  text-transform: none;
  border-left: solid 1px var(--v-separator-base);
  margin: 10px 0;
}
.v-tab:last-child {
  border-right: solid 1px var(--v-separator-base);
  margin: 10px 0;
}
</style>
