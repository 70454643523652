<template>
    <div>
      <v-card-text>
        <v-row v-if="details">
          <v-col cols="12" lg="4" md="6">
            <v-card class="h-100" outlined flat>
              <v-form v-model="valid" ref="setWebhookUrl">
                <v-card-text class="pa-4">
                  <h3 class="primary--text mb-2">
                    {{ $t('companiesDetails.webhook') }}
                  </h3>
                  <v-row class="py-2">
                    <v-col cols="12" class="py-1">
                      <label>{{ $t('companiesDetails.webhookUrl') }}</label>
                      <v-text-field
                        v-model="details.webhookUrl"
                        outlined
                        dense
                        :rules="httpsUrl"
                      />
                    </v-col>
                  </v-row>
                  <div class="d-flex py-1">
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        outlined
                        @click="setWebhookUrl"
                        :loading="setWebhookUrlLoading"
                        :disabled="!valid"
                      >
                        {{ $t('companiesDetails.setWebhookUrl') }}
                      </v-btn>
                  </div>
                  <div v-if="details.isWebhookUrlSet" class="d-flex py-1">
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        outlined
                        @click="openWebhookSecretKeyDialog"
                        :loading="regenerateWebhookSecretKeyLoading"
                        :disabled="loading"
                      >
                        {{ $t('companiesDetails.secretKey') }}
                      </v-btn>
                  </div>
                </v-card-text>
              </v-form>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text>
        <v-row v-if="details">
          <v-col cols="12" xl="6" lg="9" md="12">
            <v-card class="h-100" outlined flat>
              <v-card-text class="pa-4">

                <h3 class="primary--text mb-2">
                  {{ $t('companiesDetails.processedWebhookMessages') }}
                </h3>

                <data-table
                  :headers="headers"
                  endpoint="/WebhookMessages/"
                  serverPagination
                >

                  <template v-slot:[`item.creationDate`]="{ item }">
                    <div>
                      {{ item.creationDate | dateFormat('DD.MM.YYYY HH:mm:ss') }}
                    </div>
                  </template>

                  <template v-slot:[`item.state`]="{ item }">
                    <v-chip
                      v-if="isWebhookMessageProcessingFailed(item.state)"
                      small
                      label
                      color="error"
                    >
                      <div class="ma-3">
                        {{ getWebhookMessageState(item.state) }}
                      </div>
                    </v-chip>
                    <div v-else class="ma-3">
                      {{ getWebhookMessageState(item.state) }}
                    </div>
                  </template>

                  <template v-slot:[`item.httpResponse`]="{ item }">
                    <v-btn
                      v-if="isWebhookMessageProcessingFailed(item.state)"
                      color="primary"
                      depressed
                      @click="openServerResponseDialog(item.httpResponse)"
                      class="ml-auto"
                    >
                      {{ $t('companiesDetails.viewServerResponse') }}
                    </v-btn>
                  </template>
                </data-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>

      <v-progress-linear v-if="shouldShowLoadingBar" indeterminate></v-progress-linear>

      <WebhookSecretKeyDialog
        v-model="webhookSecretKeyDialog"
        :secretKey="secretKey"
        @close="closeWebhookSecretKeyDialog"
        @secretKeyRegenerated="updateSecretKey"
      />

      <ServerResponseDialog
        v-model="serverResponseDialog"
        :httpResponse="httpResponse"
        @close="closeServerResponseDialog"
      />

    </div>
  </template>
<script>

import WebhookSecretKeyDialog from './Dialogs/WebhookSecretKeyDialog.vue'
import ServerResponseDialog from './Dialogs/ServerResponseDialog.vue'
import Service from '@/services'
import { mapGetters } from 'vuex'
import { getEnumDescription } from '@/utils/helpers'
import * as webhookMessageState from '@/types/webhook-message-state'

export default {
  name: 'IntegrationApi',
  data () {
    return {
      details: null,
      valid: true,
      httpsUrl: [
        (v) => (/(https):\/\//i.test(v)) || this.$t('validation.httpsUrl')
      ],
      webhookSecretKeyDialog: false,
      secretKey: '',
      setWebhookUrlLoading: false,
      regenerateWebhookSecretKeyLoading: false,
      loading: false,
      serverResponseDialog: false,
      httpResponse: {
        content: '',
        statusCode: 0
      },
      headers: [
        {
          text: this.$t('companiesDetails.webhookMessages.id'),
          value: 'id',
          sortable: false
        },
        {
          text: this.$t('companiesDetails.webhookMessages.creationDate'),
          value: 'creationDate',
          sortable: false
        },
        {
          text: this.$t('companiesDetails.webhookMessages.state'),
          value: 'state',
          sortable: false
        },
        {
          value: 'httpResponse',
          sortable: false,
          align: 'center'
        }
      ]
    }
  },
  components: {
    WebhookSecretKeyDialog,
    ServerResponseDialog
  },
  props: {
    isMyCompanyRoute: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      enums: 'enums'
    }),
    shouldShowLoadingBar () {
      return this.setWebhookUrlLoading || this.regenerateWebhookSecretKeyLoading || this.loading
    }
  },
  async created () {
    this.loading = true
    await this.fetchDataForUser()
  },
  methods: {
    async fetchDataForUser () {
      try {
        const companyId = this.isMyCompanyRoute ? this.$store.getters.userCommonData.companyId : this.$route.params.id
        const { data } = await Service.get('/Company/GetCompanyDetails', { companyId: companyId })
        this.details = data
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    openWebhookSecretKeyDialog () {
      this.secretKey = this.details.webhookSecretKey
      this.webhookSecretKeyDialog = true
    },
    closeWebhookSecretKeyDialog () {
      this.webhookSecretKeyDialog = false
    },
    async setWebhookUrl () {
      this.$refs.setWebhookUrl.validate()
      if (!this.valid) {
        return
      }

      this.setWebhookUrlLoading = true

      try {
        const response = await Service.put('Company/SetWebhookUrl', {
          url: this.details.webhookUrl
        })

        if (response.data) {
          this.secretKey = response.data
          this.webhookSecretKeyDialog = true
        }

        await this.$store.dispatch('setSuccessNotification',
          this.$t('common.confirmOperation'))

        this.fetchDataForUser()
      } catch (e) {
      } finally {
        this.setWebhookUrlLoading = false
      }
    },
    getWebhookMessageState (state) {
      return getEnumDescription(this.enums.webhookMessageState, state)
    },
    openServerResponseDialog (httpResponse) {
      this.httpResponse = httpResponse
      this.serverResponseDialog = true
    },
    closeServerResponseDialog () {
      this.serverResponseDialog = false
    },
    isWebhookMessageProcessingFailed (state) {
      return state === webhookMessageState.PROCESSINGFAILED
    },
    updateSecretKey (key) {
      this.secretKey = key
    }
  }
}
</script>
